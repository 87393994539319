class SubscriptionModel {
    constructor(subscription) {
        this.methodOfBilling = 'NORMAL';
        this.userID = '';
        this.isSubscribed = false;
        this.isCorporate = false;
        this.subscriptionFailed = false;
        this.errorMessage = '';
        this.subscriptionID = '';
        this.purchasedDate = '';
        this.integration = [];
        this.userLimit = 0;
        this.amount = 0;
        this.bioCreditsID = 0;
        this.bioCreditsFaceID = 0;
        this.backgroundCheckCredits = 0;
        this.ocrCheckCredits = 0;
        this.identityCreditsVeridocOcrCompleted = 0;
        this.identityCreditsVeridocFaceIdCompleted = 0;
        this.identityCreditsVeridocBackgroundCheckCompleted = 0;
        this.identityCreditsIncodeP1 = 0;
        this.identityCreditsIncodeP2 = 0;
        this.identityCreditsIncodeP3 = 0;
        this.identityCreditsIncodeP1Completed = 0;
        this.identityCreditsIncodeP2Completed = 0;
        this.identityCreditsIncodeP3Completed = 0;
        this.documentLimit = 0;
        this.expiryDate = '';
        this.planName = 'TRIAL';
        this.isSubscriptionCancelled = true;
        this.numberOfDocumentsUploaded = 0;
        this.numberOfDocumentsCompleted = 0;
        this.numberOfUserAdded = 1;
        this.addedOn = '';
        this.modifiedOn = '';
        this.subscriptionType = '';
        this.changedSubscription = {};
        this.isActivateFaceID = false;
        this.isActivateID = false;
        this.isGeolocationActivate = false;
        this.isDisabledEmailSend = false;
        this.isActivateBackgroundCheckValidation = false;
        this.isWhiteLabel = false;
        this.disableSignatureUrlInMail = false;
        this.authenticateNotification = false;
        this.authenticateUrl = '';
        this.whiteLabel = {};
        this.isMassiveSignature = false;
        this.isEndorsementMassiveSignature = false;
        this.isFormat = false;
        this.isOrderSign = false;
        this.saleName = 'weetrust';
        this.identityProvider = 'VERIDOC';
        this.isSignatureText = false;
        this.defaultCountry = '';
        this.usage = {};
        this.maxTemplates = 10;
        this.autocaptureSDKEnabled = false;
        this.whatsappCredits = 0;
        this.hideDeleteDocumentSubUser = false;
        this.whatsappConsentAccepted = false;
        this.attachmentConsentAccepted = false;
        this.isActiveAttachments = false;
        this.isRatification = false;
        this.scoreVerification = 0.6;
        this.isPassiveLiveness = false;
        this.hasCaptureV2 = false;
        this.isEndorsementSpecial = false;
        this.isAutoSign = false;
        this.isAutoSignDefault = false;
        this.staticPositionVersion = '';
      this.enableWhatsappForTemplates = false;
      this.isDisableDrift = false;
      this.enableAlivePro = false;
        if (subscription) {
            this.setData(subscription);
        }
    }

    setData(subscription) {
        this.methodOfBilling = subscription.methodOfBilling || 'NORMAL';
        this.userID = subscription.userID || '';
        this.errorMessage = subscription.errorMessage || '';
        this.subscriptionFailed = subscription.subscriptionFailed || false;
        this.isSubscribed = subscription.isSubscribed || false;
        this.isCorporate = subscription.isCorporate || false;
        this.subscriptionID = subscription.subscriptionID || '';
        this.purchasedDate = subscription.purchasedDate || '';
        this.isActivateFaceID = subscription.isActivateFaceID || false;
        this.isActivateID = subscription.isActivateID || false;
        this.isGeolocationActivate = subscription.isGeolocationActivate || false;
        this.isDisabledEmailSend = subscription.isDisabledEmailSend || false;
        this.isActivateBackgroundCheckValidation = subscription.isActivateBackgroundCheckValidation || false;
        this.integration = [];

        if (subscription.metadata && Object.keys(subscription.metadata).length) {
            for (let key in subscription.metadata) {
                if (key === 'users' || key === 'documents' || subscription.metadata[key] === 'false')
                    continue;
                this.integration.push(key)
            }
            this.userLimit = subscription.metadata.users ? (typeof subscription.metadata.users === 'string') ? Number(subscription.metadata.users) : subscription.metadata.users : 0;
            this.documentLimit = subscription.metadata.documents ? (typeof subscription.metadata.documents === 'string') ? Number(subscription.metadata.documents) : subscription.metadata.documents : 0;
        } else {
            this.integration = subscription.integration ? subscription.integration : [];
            this.userLimit = subscription.userLimit ? (typeof subscription.userLimit === 'string') ? Number(subscription.userLimit) : subscription.userLimit : 0;
            this.documentLimit = subscription.documentLimit ? (typeof subscription.documentLimit === 'string') ? Number(subscription.documentLimit) : subscription.documentLimit : 0;
        }

        this.expiryDate = subscription.expiryDate || '';
        this.amount = parseInt(subscription.amount) || 0;
        this.bioCreditsID = parseInt(subscription.bioCreditsID) || 0;
        this.bioCreditsFaceID = parseInt(subscription.bioCreditsFaceID) || 0;
        this.backgroundCheckCredits = parseInt(subscription.backgroundCheckCredits) || 0;
        this.ocrCheckCredits = parseInt(subscription.ocrCheckCredits) || 0;
        this.identityCreditsVeridocOcrCompleted = parseInt(subscription.identityCreditsVeridocOcrCompleted) || 0;
        this.identityCreditsVeridocFaceIdCompleted = parseInt(subscription.identityCreditsVeridocFaceIdCompleted) || 0;
        this.identityCreditsVeridocBackgroundCheckCompleted = parseInt(subscription.identityCreditsVeridocBackgroundCheckCompleted) || 0;
        this.identityCreditsIncodeP1 = parseInt(subscription.identityCreditsIncodeP1) || 0;
        this.identityCreditsIncodeP2 = parseInt(subscription.identityCreditsIncodeP2) || 0;
        this.identityCreditsIncodeP3 = parseInt(subscription.identityCreditsIncodeP3) || 0;
        this.identityCreditsIncodeP1Completed = parseInt(subscription.identityCreditsIncodeP1Completed) || 0;
        this.identityCreditsIncodeP2Completed = parseInt(subscription.identityCreditsIncodeP2Completed) || 0;
        this.identityCreditsIncodeP3Completed = parseInt(subscription.identityCreditsIncodeP3Completed) || 0;
        this.planName = subscription.planName || 'TRIAL';
        this.isSubscriptionCancelled = !!subscription.isSubscriptionCancelled;
        this.numberOfDocumentsUploaded = parseInt(subscription.numberOfDocumentsUploaded) || 0;
        this.numberOfDocumentsCompleted = parseInt(subscription.numberOfDocumentsCompleted) || 0;
        this.numberOfUserAdded = parseInt(subscription.numberOfUserAdded) || 1;
        this.subscriptionType = subscription.subscriptionType ? subscription.subscriptionType : '';
        this.addedOn = subscription.addedOn || new Date().getTime();
        this.modifiedOn = subscription.modifiedOn || new Date().getTime();
        this.isWhiteLabel = subscription.isWhiteLabel ? subscription.isWhiteLabel : false;
        this.disableSignatureUrlInMail = subscription.disableSignatureUrlInMail || false;
        this.authenticateNotification = subscription.authenticateNotification || false;
        this.authenticateUrl = subscription.authenticateUrl || '';
        this.whiteLabel = {
            name: subscription.whiteLabel && subscription.whiteLabel.name ? subscription.whiteLabel.name : '',
            color: subscription.whiteLabel && subscription.whiteLabel.color ? subscription.whiteLabel.color : '',
            logo: subscription.whiteLabel && subscription.whiteLabel.logo ? subscription.whiteLabel.logo : '',
            signBox: subscription.whiteLabel && subscription.whiteLabel.signBox ? subscription.whiteLabel.signBox : '',
            biometricLogo: subscription.whiteLabel && subscription.whiteLabel.biometricLogo ? subscription.whiteLabel.biometricLogo : '',
            url: subscription.whiteLabel && subscription.whiteLabel.url ? subscription.whiteLabel.url : '',
            templateAttached: subscription.whiteLabel && subscription.whiteLabel.templateAttached ? subscription.whiteLabel.templateAttached : '',
            templateEfirma: subscription.whiteLabel && subscription.whiteLabel.templateEfirma ? subscription.whiteLabel.templateEfirma : '',
            templateEfirmaGeo: subscription.whiteLabel && subscription.whiteLabel.templateEfirmaGeo ? subscription.whiteLabel.templateEfirmaGeo : '',
            templateRegister: subscription.whiteLabel && subscription.whiteLabel.templateRegister ? subscription.whiteLabel.templateRegister : '',
            templateCertificate: subscription.whiteLabel && subscription.whiteLabel.templateCertificate ? subscription.whiteLabel.templateCertificate : '',
            subjectEmail: subscription.whiteLabel && subscription.whiteLabel.subjectEmail ? subscription.whiteLabel.subjectEmail : '',
            templateHsbc: subscription.whiteLabel && subscription.whiteLabel.templateHsbc ? subscription.whiteLabel.templateHsbc : false,
        };
        this.isFormat = subscription.isFormat || false;
        this.isOrderSign = subscription.isOrderSign || false;
        this.isMassiveSignature = subscription.isMassiveSignature || false;
        this.isEndorsementMassiveSignature = subscription.isEndorsementMassiveSignature || false;
        this.saleName = subscription.saleName || 'Weesign';
        this.identityProvider = subscription.identityProvider || 'VERIDOC';
        this.isSignatureText = subscription.isSignatureText ? subscription.isSignatureText : false;
        this.defaultCountry = subscription.defaultCountry ? subscription.defaultCountry : '';
        if(subscription.changedSubscription) {
            this.changedSubscription ={
                planName: subscription.changedSubscription && subscription.changedSubscription.planName ? subscription.changedSubscription.planName:'',
                subscriptionID: subscription.changedSubscription && subscription.changedSubscription.subscriptionID ? subscription.changedSubscription.subscriptionID:''
            }
        }
        this.usage = {
            documentsCompleted: parseInt(subscription.usage?.documentsCompleted) || 0,
            packA:  parseInt(subscription.usage?.packA) || 0,
            packB: parseInt(subscription.usage?.packB) || 0,
            packC: parseInt(subscription.usage?.packC) || 0,
        }
        this.maxTemplates = parseInt(subscription.maxTemplates) || 10;
        this.autocaptureSDKEnabled = subscription.autocaptureSDKEnabled || false;
        this.whatsappCredits = parseInt(subscription.whatsappCredits || 0);
        this.hideDeleteDocumentSubUser = subscription.hideDeleteDocumentSubUser || false;
        this.whatsappConsentAccepted = subscription.whatsappConsentAccepted || false;
        this.attachmentConsentAccepted = subscription.attachmentConsentAccepted || false;
        this.isActiveAttachments = subscription.isActiveAttachments || false;
        this.isRatification = subscription.isRatification || false;
        this.scoreVerification = subscription.scoreVerification || 0.6;
        this.isPassiveLiveness = subscription.isPassiveLiveness || false;
        this.hasCaptureV2 = subscription.hasCaptureV2 || false;
        this.isEndorsementSpecial = subscription.isEndorsementSpecial || false;
        this.staticPositionVersion = subscription.staticPositionVersion || '';
      this.enableWhatsappForTemplates = subscription.enableWhatsappForTemplates || '';
      this.isDisableDrift = subscription.isDisableDrift || false;
      this.enableAlivePro = subscription.enableAlivePro || false;
      this.isAutoSign = subscription.isAutoSign || false;
      this.isAutoSignDefault = subscription.isAutoSignDefault || false;
    }

}

export default SubscriptionModel;
