import {getSignedURL} from '../AWSService';
import { genericConstants, formSignatoryConstants, sourceConstants } from '../constant';

class DocumentModel {
  constructor(document) {
    this.documentID = '';
    this.title = '';
    this.documentType = '';
    this.country = '';
    this.verificationType = '';
    this.status = '';
    this.documentSignType = '';
    this.nickname = '';
    this.documentFileObj = {
      url: '',
      key: '',
      originalKey: '',
      pendingForBlockChain: '',
      version: '',
      size: ''
    };
    this.documentFiles = [];
    this.signatory = [];
    this.documentImages = [];

    this.createdBy = {
      userID: '',
      adminID: '',
      fullName: '',
      emailID: '',
      photo: [],
      isSigned: '',
      signatureType: '',
      signature: {},
      biometric: null,
      isInvalidSignature: false,
      invalidSignatureReason: '',
      biometricApprovedByUser: false,
      biometrics: [],
    };

    this.sharedWith = [];
    this.processingStatus = 'PENDING';
    this.language = localStorage.englishLang ? localStorage.englishLang : 'es';
    this.fieldArray = [];
    this.addedOn = 0;
    this.modifiedOn = 0;
    this.isDeleted = 0;
    this.isInactive = 0;
    this.documnetURL = '';
    this.isActiveGeolocation = false;
    this.autoSign = false;
    this.isBiometricIDEnabled = false;
    this.isBiometricFaceIDEnabled = false;
    this.isBiometricBackgroundCheckEnabled = false;
    this.identityProvider = '';
    this.staticSignPositions = [];
    this.isForceID = false;
    this.version = 1;
    this.tags = [];
    this.hasOrder = false;
    this.savedDocument = {
      signatory: [],
      sharedWith: [],
      staticSignPositions: [],
      isActiveGeolocation: false,
      title: '',
      message: '',
      hasOrder: false,
      documentType: '',
      documentSignType: '',
    };
    this.isSignatureText = false;
    this.logsStatus = [];
    this.forwardings = [];
    this.source = sourceConstants.TYPE.DOCUMENT;
    this.isFormSignatory = false;
    this.formSignatory = {
      fields: [],
    };
    this.formStatus = '';
    this.templateID = '';
    this.hasLegalPrivacyPolicy = false;
    this.neu = '';
    this.isEndorsement = false;
    this.isVoBo = false;
    this.voBoStatus = '';
    this.voBo = [];
    this.autocaptureSDKEnabled = false;
    this.isPassiveLiveness = false;
    this.hasCaptureV2 = false;
    this.splitOriginDocumentId = '';
    this.isRatification = false;
    this.ratificationFileName = false;
    this.ratificationGuideFileName = false;
    this.ratificationDocument = false;
    this.veridasSdkDocumentType = '';
    this.veridasSdkDefaultCountry = '';
    this.veridasSdkResidentPermit = false;
    this.splitNumber = null;
    this.endorsementSpecialPage = '';
    this.endorsementWorkflow = '';
    this.endorsementWorkflowDocumentId = '';
    this.isDisableDrift = false;
    this.enableAlivePro = false;

    if (document) this.setData(document);
  }

  setData(document) {
    this.documentID = document.documentID;
    this.title = document.title;
    this.documentType = document.documentType;
    this.country = document.country;
    this.verificationType = document.verificationType;
    this.status = document.status || genericConstants.DOCUMENT_STATUS.DRAFT.toUpperCase();
    this.documentSignType = document.documentSignType;
    this.nickname = document.nickname;
    this.sharedWith = (document.sharedWith && document.sharedWith.length > 0) && document.sharedWith.map((item) => ({emailID: item.emailID, sharedWithID: item.sharedWithID}));
    this.staticSignPositions = (document.staticSignPositions && document.staticSignPositions.length > 0)
      && document.staticSignPositions.map((item) => ({
        user: item && item.user ? item.user : {
          email: '',
        },
        coordinates: item && item.coordinates ? item.coordinates : {
          x: 0,
          y: 0,
        },
        parentImageSize: item && item.parentImageSize ? item.parentImageSize : {
          width: 0,
          height: 0,
        },
        viewport: item && item.viewport ? item.viewport : {
          width: 0,
          height: 0,
        },
        color: item && item.color ? item.color : '',
        page: item && item.page ? item.page : 0,
        imageSize: item && item.imageSize ? item.imageSize : {
          width: 0,
          height: 0,
        },
        pageY: item && item.pageY ? item.pageY : 0,
        version: item && item.version ? item.version : 0,
      }));

    if (document.documentFileObj) {
      this.documentFileObj = {
        url: document.documentFileObj.url,
        key: document.documentFileObj.key,
        originalKey: document.documentFileObj.originalKey,
        version: document.documentFileObj.version,
        size: document.documentFileObj.size,
        pendingForBlockChain: document.documentFileObj.pendingForBlockChain
      };
    } else {
      this.documentFileObj = {};
    }
    this.documentFiles = document.documentFiles || [];
    let signatoryArray = [];
    if (document.signatory && document.signatory.length > 0) {
      document.signatory.forEach((signatoryObj) => {
        let signatory = {
          _id: signatoryObj._id || '',
          signatoryID: signatoryObj.signatoryID || '',
          name: signatoryObj.name || '',
          emailID: signatoryObj.emailID || '',
          emailTracking: signatoryObj.emailTracking || [],
          verificationCode: signatoryObj.verificationCode || '',
          signing: {
            url: signatoryObj.signing && signatoryObj.signing.url ? signatoryObj.signing.url : '',
            expiry: signatoryObj.signing && signatoryObj.signing.expiry ? signatoryObj.signing.expiry : 0
          },
          order: signatoryObj.order || 0,
          photo: signatoryObj.photo || [],
          isSigned: signatoryObj.isSigned || 0,
          signatureType: signatoryObj.signatureType || '',
          signature: signatoryObj.signature || {},
          fileName: signatoryObj.fileName || '',
          addedOn: signatoryObj.addedOn || 0,
          fieldArray: signatoryObj.fieldArray || [],
          forceBiometric: {
            forcedPhotoID: signatoryObj.forceBiometric && signatoryObj.forceBiometric.forcedPhotoID || false,
            forcedID: signatoryObj.forceBiometric && signatoryObj.forceBiometric.forcedID || false,
            forcedFaceID: signatoryObj.forceBiometric && signatoryObj.forceBiometric.forcedFaceID || false,
            forcedOcr: signatoryObj.forceBiometric && signatoryObj.forceBiometric.forcedOcr || false,
            forcedBackgroundCheck: signatoryObj.forceBiometric && signatoryObj.forceBiometric.forcedBackgroundCheck || false,
          },
          isInvalidSignature: signatoryObj.isInvalidSignature || false,
          invalidSignatureReason: signatoryObj.invalidSignatureReason || '',
          hasBiometricDocument: signatoryObj.hasBiometricDocument || '',
          biometricApprovedByUser: signatoryObj.biometricApprovedByUser || false,
          biometric: signatoryObj.biometric || null,
          identitySessionId: signatoryObj.identitySessionId || null,
          biometrics: signatoryObj.biometrics,
          customerId: signatoryObj.customerId,
          attachment: signatoryObj.attachment || [],
          isByEndorsement: signatoryObj.isByEndorsement ?? false,
          phone: signatoryObj.phone || '',
        };
        signatoryArray.push(signatory);
      });
    }

    this.signatory = signatoryArray;

    if (document && document.createdBy) {
      this.createdBy = {
        userID: document.createdBy.userID,
        adminID: document.createdBy.adminID ? document.createdBy.adminID : '',
        fullName: document.createdBy.fullName,
        emailID: document.createdBy.emailID,
        photo: document.createdBy.photo,
        isSigned: document.createdBy.isSigned,
        signatureType: document.createdBy.signatureType,
        signature: document.createdBy.signature,
        biometric: document.createdBy.biometric || null,
        isInvalidSignature: document.createdBy.isInvalidSignature,
        invalidSignatureReason: document.createdBy.invalidSignatureReason,
        biometricApprovedByUser: document.createdBy.biometricApprovedByUser,
        biometrics: document.createdBy.biometrics || [],
      };
    } else {
      this.createdBy = {};
    }

    this.hasOrder = document.hasOrder || false;
    this.processingStatus = document.processingStatus ? document.processingStatus : 'PENDING';
    this.language = document.language ? document.language : localStorage.englishLang;
    this.fieldArray = document.fieldArray;
    this.addedOn = document.addedOn ? document.addedOn : (new Date).getTime();
    this.modifiedOn = document.modifiedOn;
    this.isDeleted = document.isDeleted;
    this.isInactive = document.isInactive;
    this.isActiveGeolocation = document.isActiveGeolocation;
    this.autoSign = document?.autoSign ?? false;
    this.isForceID = document.isForceID || false;
    this.isBiometricIDEnabled = document.isBiometricIDEnabled || false;
    this.isBiometricBackgroundCheckEnabled = document.isBiometricBackgroundCheckEnabled || false;
    this.isBiometricFaceIDEnabled = document.isBiometricFaceIDEnabled || false;
    this.identityProvider = document.identityProvider;
    this.isSignatureText = document.isSignatureText || false;
    this.version = document.version;
    if (document.documentFileObj && document.documentFileObj.key)
      this.documnetURL = getSignedURL(document.documentFileObj.key);

    let documentImageArray = [];
    if (document.documentImage && document.documentImage.length > 0) {
      document.documentImage.map(function (documentImageObj) {
        let signatory = {
          key: documentImageObj.key || '',
          version: documentImageObj.version || '',
          imageURL: getSignedURL(documentImageObj.key) || '',
          documentImageID: documentImageObj._id || '',
          inputFieldArray: documentImageObj.inputFieldArray || []
        };
        documentImageArray.push(signatory);
      });
    }
    this.documentImages = documentImageArray;
    this.tags = document.tags;
    const {savedDocument} = document;
    this.savedDocument = {
      signatory: savedDocument && savedDocument.signatory && savedDocument.signatory.length ? savedDocument.signatory : [],
      sharedWith: savedDocument && savedDocument.sharedWith && savedDocument.sharedWith.length ? savedDocument.sharedWith : [],
      staticSignPositions: savedDocument && savedDocument.staticSignPositions && savedDocument.staticSignPositions.length ? savedDocument.staticSignPositions : [],
      isActiveGeolocation: savedDocument && savedDocument.isActiveGeolocation ? savedDocument.isActiveGeolocation : false,
      title: savedDocument && savedDocument.title ? savedDocument.title : '',
      message: savedDocument && savedDocument.message ? savedDocument.message : '',
      hasOrder: savedDocument && savedDocument.hasOrder ? savedDocument.hasOrder : false,
      documentType: savedDocument && savedDocument.documentType ? savedDocument.documentType : '',
      documentSignType: savedDocument && savedDocument.documentSignType ? savedDocument.documentSignType : '',
    };
    this.logsStatus = document.logsStatus;
    this.forwardings = document.forwardings;

    const isFormSource = document.source && document.source === genericConstants.DOCUMENT_SOURCE.FORM;
    const hasFormFields = document.formSignatory && document.formSignatory.length > 0 && document.formSignatory[0].fields && document.formSignatory[0].fields.length > 0;

    if (isFormSource && hasFormFields) {
      this.source = document.source;
      this.isFormSignatory = true;
      this.formSignatory.fields = document.formSignatory[0].fields;
      this.formStatus = document.formStatus ?? formSignatoryConstants.STATUS.INITIAL;
    }

    this.templateID = document.templateID;
    this.hasLegalPrivacyPolicy = document.hasLegalPrivacyPolicy ?? false;
    this.neu = document.neu || '';
    this.isEndorsement = document.isEndorsement ?? false;
    this.isVoBo = document.isVoBo || false;
    this.voBoStatus = document.voBoStatus || '';
    this.voBo = document.voBo || [];
    this.autocaptureSDKEnabled = document.autocaptureSDKEnabled || false;
    this.splitOriginDocumentId = document.splitOriginDocumentId;
    this.isRatification = document.isRatification || false;
    this.ratificationFileName = document.ratificationFileName || false;
    this.ratificationGuideFileName = document.ratificationGuideFileName || false;
    this.ratificationDocument = document.ratificationDocument || false;
    this.veridasSdkDocumentType = document.veridasSdkDocumentType || '';
    this.veridasSdkDefaultCountry = document.veridasSdkDefaultCountry || '';
    this.veridasSdkResidentPermit = document.veridasSdkResidentPermit || false;
    this.splitNumber = document.splitNumber || null;
    this.isPassiveLiveness = document.isPassiveLiveness || false;
    this.hasCaptureV2 = document.hasCaptureV2 || false;
    this.endorsementSpecialPage = document.endorsementSpecialPage || '';
    this.endorsementWorkflow = document.endorsementWorkflow || null;
    this.endorsementWorkflowDocumentId = document.endorsementWorkflowDocumentId || null;
    this.isDisableDrift = document.isDisableDrift || false;
    this.enableAlivePro = document.enableAlivePro || false;
  }
}

export default DocumentModel;
